const DEFAULT_EVENTS = [
  "scroll",
  "wheel",
  "mousewheel",
  "resize",
  "animationend",
  "transitionend",
  "touchmove"
];
const inBrowser = typeof window !== "undefined";

function isItScrollableWithoutVisibleScrollbars(el) {
  return (
    el &&
    el.scrollHeight > el.offsetHeight &&
    !(el.offsetWidth > el.scrollWidth)
  );
}

function testSupportsPassive() {
  if (!inBrowser) return;
  let support = false;
  try {
    let opts = Object.defineProperty({}, "passive", {
      get: function() {
        support = true;
        return support;
      }
    });
    if (window) {
      window.addEventListener("test", null, opts);
    }
  } catch (e) {
    support = false;
  }
  return support;
}

const supportsPassive = testSupportsPassive();

function on(el, func, capture = false) {
  listener.opertion(true, el, func, capture);
}

function off(el, func, capture = false) {
  listener.opertion(false, el, func, capture);
}

const listener = {
  opertion(isOn, el, func, capture = false) {
    if (!inBrowser) return;
    let parent = el.$parent;
    let Scrollable = isItScrollableWithoutVisibleScrollbars(parent);
    let fn = listener[isOn ? "_on" : "_off"];
    if (Scrollable) {
      DEFAULT_EVENTS.forEach(function(evt) {
        fn(el, evt, func, capture);
      });
    }
    DEFAULT_EVENTS.forEach(function(evt) {
      fn(window, evt, func, capture);
    });
  },
  _on(el, type, func, capture = false) {
    if (supportsPassive) {
      // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Improving_scrolling_performance_with_passive_listeners
      el.addEventListener(type, func, {
        capture: capture,
        passive: true
      });
    } else {
      el.addEventListener(type, func, capture);
    }
  },
  _off(el, type, func, capture = false) {
    el.removeEventListener(type, func, capture);
  }
};

export { DEFAULT_EVENTS, inBrowser, on, off };
