<template>
  <span
    :style="{ height, width: computedWidth }"
    class="SkeletonBox"
  />
</template>

<script>
export default {
  props: {
    maxWidth: {
      default: 100,
      type: Number
    },
    minWidth: {
      default: 80,
      type: Number
    },
    height: {
      default: `1em`,
      type: String
    },
    width: {
      default: `100%`,
      type: String
    }
  },
  computed: {
    computedWidth() {
      return (
        this.width ||
        `${Math.floor(
          Math.random() * (this.maxWidth - this.minWidth) + this.minWidth
        )}%`
      );
    }
  }
};
</script>

<style scoped>
.SkeletonBox {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  background-color: #dddbdd;
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.SkeletonBox::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, hsla(0, 0%, 100%, 0)),
    color-stop(20%, hsla(0, 0%, 100%, 0.2)),
    color-stop(60%, hsla(0, 0%, 100%, 0.5)),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.2) 20%,
    hsla(0, 0%, 100%, 0.5) 60%,
    hsla(0, 0%, 100%, 0)
  );
  -webkit-animation: shimmer 5s infinite;
  animation: shimmer 5s infinite;
  content: "";
}
</style>
