<template>
  <div id="ctn">
    <!-- 年货节 -->
    <!-- <div v-if="layer" class="layer">
      <div>
        <img class="close" src="@/assets/icon/close.png" @click="layer = false">
        <router-link to="/IMBArea"><img class="btn" src="@/assets/icon/btn.png"></router-link>
        <img class="nianhuojie" src="@/assets/images/nianhuojie.png" alt="">
      </div>
    </div> -->

    <div class="banner" style="min-width:1200px">
      <div>
        <Carousel v-if="banner" v-slot="{item}" class="carousel" height="500px" :items="banner">
          <a v-if="item.url" :href="item.url">
            <!-- <ImowImg :img-id="item.imgSrc" :size="'1000x500'" :lazy="true" alt="首页轮播" /> -->
            <div :style="{ 'background-image': 'url(https://imow-upload.oss-cn-hangzhou.aliyuncs.com/' + item.imgSrc + ')','background-repeat':'no-repeat' }" class="bannerBox" />
            <!-- <img :src="'https://imow-upload.oss-cn-hangzhou.aliyuncs.com/'+item.imgSrc" alt="首页轮播" style="width:100%;height:500px"> -->
          </a>
        </Carousel>
      </div>
    </div>
    <div style="width: 1200px; margin: 0 auto;">
      <!-- <div class="mediaObj">
        <a v-for="(item,index) in mediaObjSrc" :key="index+'a'" :href="item.url"><img :src="'https://imow-upload.oss-cn-hangzhou.aliyuncs.com/'+item.imgSrc" alt="媒体图片"></a>
      </div> -->
      <div class="icon-title" style="margin-top: 20px; margin-bottom: 15px;">
        <div>
          <h4><img src="@/assets/images/favorite.png" alt="猜你喜欢"> 猜你喜欢</h4>
        </div>
      </div>
      <FavoritePane />
      <FloorProvider />

    </div>
  </div>
</template>


<script>
import { LazyComponent } from "@/components/layload";
import getBannerItemList from "@/api/index/getBannerItemList.js";
import getVideoEntrance from "@/api/index/getVideoEntrance.js";
import { eventBus, SHOWCATRGORYBAT, HIDECATRGORYBAT } from "@/util/eventBus";
import Carousel from "@/components/layloadCarousel/main.vue";

export default {
  components: {
    Carousel,
    FavoritePane: LazyComponent({
      component: () => import("@/components/index/FavoritePane.vue")
    }),
    FloorProvider: LazyComponent({
      component: () => import("@/components/index/FloorProvider.vue")
    })
  },
  data() {
    return {
      banner: "",
      mediaObjSrc: ""
      // layer: true
    };
  },

  layout: "fullScreenNoSideBar",
  async asyncData() {
    let banner = (await getBannerItemList()).bannerItemList; //eslint-disable-line

    let mediaObjSrc = (await getVideoEntrance()).videoEntranceList;
    return { banner, mediaObjSrc };
  },

  mounted() {
    // this.getpush();
    // let _this = this;
    // window.addEventListener("scroll", _this.windowScroll);
  },
  beforeDestroy() {
    // window.removeEventListener("scroll", this.windowScroll);
  },
  methods: {
    // getpush() {
    //   var sUserAgent = navigator.userAgent.toLowerCase();
    //   var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    //   var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    //   var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    //   var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    //   var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    //   var bIsAndroid = sUserAgent.match(/android/i) == "android";
    //   var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    //   var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    //   if (
    //     bIsIpad ||
    //     bIsIphoneOs ||
    //     bIsMidp ||
    //     bIsUc7 ||
    //     bIsUc ||
    //     bIsAndroid ||
    //     bIsCE ||
    //     bIsWM
    //   ) {
    //     //跳转移动端页面
    //     this.$router.push("star");
    //   }
    // },
    windowScroll() {
      let topEl = document.getElementById("floor0"); //eslint-disable-line
      let bottomEl = document.getElementById("floor" + (this.floor.length - 1));
      if (!topEl || !bottomEl) return;
      let top = topEl.getBoundingClientRect().top;
      let bottom = bottomEl.getBoundingClientRect().top;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //console.log(scrollTop); //eslint-disable-line
      //指示第一层楼和最后一层楼距离窗口顶部的高度
      //console.log(top); //eslint-disable-line
      //console.log("bottom:"+bottom); //eslint-disable-line
      if (top > 200) {
        this.show = false;
      } else if (top <= 200 && bottom >= -220) {
        this.show = true;
      } else {
        this.show = false;
      }
      var fl = document.getElementsByClassName("fl"); //随着滚动条，楼梯栏跟着变
      let i;
      for (i in fl) {
        if (scrollTop - fl[i].offsetTop < 691) {
          this.addClass(i);
          return false;
        }
      }
    },
    addClass: function(index) {
      this.current = index;
    }
  },
  // memory-leak
  beforeRouteEnter(to, from, next) {
    eventBus.$emit(SHOWCATRGORYBAT);
    next();
  },
  // memory-leak
  beforeRouteLeave(to, from, next) {
    eventBus.$emit(HIDECATRGORYBAT);
    next();
  }
};
</script>
<style scoped>
/* 年货节 */

/*.layer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1001;
}

.layer > div {
  width: 1350px;
  height: 600px;
  font-size: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -675px;
  margin-top: -400px;
}

 .layer > div .close {
  position: absolute;
  top: 110px;
  right: 210px;
  cursor: pointer;
}

.layer > div .btn {
  position: absolute;
  top: 410px;
  left: 200px;
} */

#ctn {
  width: 100%;
  margin: 0 auto;
  background-color: #f2f2f2;
}

.banner {
  background-color: #993333;
}

.bannerBox {
  width: 100%;
  height: 500px;
  background-position-x: center;
}
/* .banner > div {
  width: 1200px;
  margin: 0 auto;
} */

/* .carousel {
  margin-left: 200px;
} */

/* .carousel {
  margin-right: 35px;
} */

.mediaObj {
  margin-top: 20px;
}

.mediaObj a {
  margin-right: 16px;
}

.mediaObj img {
  width: 389px;
  height: 189px;
}

.mediaObj a:last-child {
  margin-right: 0px;
}

/* 猜你喜欢 */
.icon-title {
  padding-bottom: 20px;
  padding-top: 20px;
}

.icon-title div {
  position: relative;
  border-top: 1px solid black;
  height: 0px;
  width: 336px;
  margin: 0 auto;
}

.icon-title div img {
  vertical-align: middle;
}

.icon-title div h4 {
  height: 40px;
  line-height: 40px;
  position: relative;
  top: -20px;
  width: 146px;
  background-color: #f2f2f2;
  text-align: center;
  margin: 0 auto;
  font-size: 20px;
  font-weight: bold;
}
</style>
