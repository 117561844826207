import { inBrowser, on, off } from "./util";
import loading from "./loading.vue";

export default ({ component, option = {} }) => {
  let asyncComponent = gen({ component, option });

  return {
    props: {
      placeholder: String,
      placeholderTag: {
        type: String,
        default: "div"
      }
    },
    functional: true,
    render(h, { parent, props }) {
      if (parent._isMounted) {
        return h(asyncComponent);
      }

      parent.$once("hook:mounted", () => {
        parent.$forceUpdate();
      });

      if (props.placeholderTag) {
        return h("");
      }
      // Return a placeholder element for each child in the default slot
      // Or if no children return a single placeholder
      return h(asyncComponent);
    }
  };
};

function gen({ component, option }) {
  let resolveComponent;
  return () => ({
    component: new Promise(resolve => {
      resolveComponent = resolve;
    }),

    loading: {
      mounted() {
        this.el = this.$el;
        this.checkInView();
        this.initListener();
      },
      render(h) {
        return h(loading, {
          props: {
            height: "300px"
          }
        });
      },
      props: {
        preLoad: {
          type: Number,
          default: option.preLoad || 0.9
        }
      },
      data() {
        return {
          el: null,
          rect: {}
        };
      },
      methods: {
        initListener() {
          on(this.el, this.checkInView);
        },
        getRect() {
          this.rect = this.$el.getBoundingClientRect();
        },
        checkInView() {
          this.getRect();
          if (
            inBrowser &&
            (this.rect.top < window.innerHeight * this.preLoad &&
              this.rect.bottom > 0) &&
            (this.rect.left < window.innerWidth * this.preLoad &&
              this.rect.right > 0)
          ) {
            this.load();
            this.destroy();
          }
        },
        load() {
          component().then(resolveComponent);
        },
        destroy() {
          off(this.el, this.checkInView);
        }
      }
    }
  });
}
