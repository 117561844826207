import { post } from "@/util/request.js";

async function getVideoEntrance(data) {
  try {
    const res = await post("getVideoEntrance", data);
    return res.data;
  } catch (error) {
    console.log(error);
    return { videoEntranceList: [] };
  }
}

export default getVideoEntrance;
