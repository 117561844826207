import { post } from "@/util/request.js";

async function getBannerItemList(data) {
  try {
    const res = await post("getBannerItemList", data);
    return res.data;
  } catch (error) {
    console.log(error);
    return { bannerItemList: [] };
  }
}

export default getBannerItemList;
