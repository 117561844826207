<template>
  <div
    :class="carouselClasses"
    @mouseenter.stop="handleMouseEnter"
    @mouseleave.stop="handleMouseLeave"
  >
    <div
      class="el-carousel__container"
      :style="{ height: height }"
    >
      <transition
        v-if="arrowDisplay"
        name="carousel-arrow-left"
      >
        <button
          v-show="(arrow === 'always' || hover) && (loop || activeIndex > 0)"
          type="button"
          class="el-carousel__arrow el-carousel__arrow--left"
          @mouseenter="handleButtonEnter('left')"
          @mouseleave="handleButtonLeave"
          @click.stop="throttledArrowClick(activeIndex - 1)"
        >
          <i class="el-icon-arrow-left" />
        </button>
      </transition>
      <transition
        v-if="arrowDisplay"
        name="carousel-arrow-right"
      >
        <button
          v-show="(arrow === 'always' || hover) && (loop || activeIndex < activeItems.length - 1)"
          type="button"
          class="el-carousel__arrow el-carousel__arrow--right"
          @mouseenter="handleButtonEnter('right')"
          @mouseleave="handleButtonLeave"
          @click.stop="throttledArrowClick(activeIndex + 1)"
        >
          <i class="el-icon-arrow-right" />
        </button>
      </transition>
      <CarouselItem
        v-for="(item,index) in activeItems"
        :key="index"
        ref="carouselItems"
      >
        <slot :item="item" />
      </CarouselItem>

    </div>
    <ul
      v-if="indicatorPosition !== 'none'"
      :class="indicatorsClasses"
    >
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="[
          'el-carousel__indicator',
          'el-carousel__indicator--' + direction,
          { 'is-active': index === activeIndex }]"
        @mouseenter="throttledIndicatorHover(index)"
        @click.stop="handleIndicatorClick(index)"
      >
        <button class="el-carousel__button" />
      </li>
    </ul>
  </div>
</template>

<script>
import throttle from "throttle-debounce/throttle";
import CarouselItem from "./item.vue";
import {
  addResizeListener,
  removeResizeListener
} from "element-ui/lib/utils/resize-event";

export default {
  name: "ElCarousel",
  components: { CarouselItem },
  props: {
    items: {
      type: Array,
      default: new Array()
    },
    initialIndex: {
      type: Number,
      default: 0
    },
    height: {
      type: String,
      default: ""
    },
    trigger: {
      type: String,
      default: "hover"
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    interval: {
      type: Number,
      default: 3000
    },
    indicatorPosition: {
      type: String,
      default: ""
    },
    indicator: {
      type: Boolean,
      default: true
    },
    arrow: {
      type: String,
      default: "hover"
    },
    type: {
      type: String,
      default: ""
    },
    loop: {
      type: Boolean,
      default: true
    },
    direction: {
      type: String,
      default: "horizontal",
      validator(val) {
        return ["horizontal", "vertical"].indexOf(val) !== -1;
      }
    }
  },

  data() {
    return {
      activeItems: [this.items[0]],
      activeIndex: -1,
      containerWidth: 0,
      timer: null,
      hover: false
    };
  },

  computed: {
    arrowDisplay() {
      return this.arrow !== "never" && this.direction !== "vertical";
    },

    carouselClasses() {
      const classes = ["el-carousel", "el-carousel--" + this.direction];
      if (this.type === "card") {
        classes.push("el-carousel--card");
      }
      return classes;
    },

    indicatorsClasses() {
      const classes = [
        "el-carousel__indicators",
        "el-carousel__indicators--" + this.direction
      ];
      if (this.hasLabel) {
        classes.push("el-carousel__indicators--labels");
      }
      if (this.indicatorPosition === "outside" || this.type === "card") {
        classes.push("el-carousel__indicators--outside");
      }
      return classes;
    }
  },

  watch: {
    activeIndex(val, oldVal) {
      this.resetItemPosition(oldVal);
      this.$emit("change", val, oldVal);
    },

    autoplay(val) {
      val ? this.startTimer() : this.pauseTimer();
    },

    loop() {
      this.setActiveItem(this.activeIndex);
    }
  },

  created() {
    this.throttledArrowClick = throttle(300, true, index => {
      this.setActiveItem(index);
    });
    this.throttledIndicatorHover = throttle(300, index => {
      this.handleIndicatorHover(index);
    });
  },

  mounted() {
    addResizeListener(this.$el, this.resetItemPosition);
    if (this.initialIndex < this.activeItems.length && this.initialIndex >= 0) {
      this.activeIndex = this.initialIndex;
    }
    this.startTimer();
  },

  beforeDestroy() {
    if (this.$el) removeResizeListener(this.$el, this.resetItemPosition);
    this.pauseTimer();
  },

  methods: {
    handleMouseEnter() {
      this.hover = true;
      this.pauseTimer();
    },

    handleMouseLeave() {
      this.hover = false;
      this.startTimer();
    },

    itemInStage(item, index) {
      const length = this.activeItems.length;
      if (
        (index === length - 1 && item.inStage && this.activeItems[0].active) ||
        (item.inStage &&
          this.activeItems[index + 1] &&
          this.activeItems[index + 1].active)
      ) {
        return "left";
      } else if (
        (index === 0 && item.inStage && this.activeItems[length - 1].active) ||
        (item.inStage &&
          this.activeItems[index - 1] &&
          this.activeItems[index - 1].active)
      ) {
        return "right";
      }
      return false;
    },

    handleButtonEnter(arrow) {
      if (this.direction === "vertical") return;
      this.activeItems.forEach((item, index) => {
        if (arrow === this.itemInStage(item, index)) {
          item.hover = true;
        }
      });
    },

    handleButtonLeave() {
      if (this.direction === "vertical") return;
      this.activeItems.forEach(item => {
        item.hover = false;
      });
    },

    resetItemPosition(oldIndex) {
      let _self = this;
      this.$refs.carouselItems.forEach((item, index) => {
        item.translateItem(index, _self.activeIndex, oldIndex);
      });
    },

    playSlides() {
      if (this.activeIndex < this.items.length - 1) {
        this.setActiveItem(this.activeIndex + 1);
      } else if (this.loop) {
        this.activeIndex = 0;
      }
    },

    pauseTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },

    startTimer() {
      if (this.interval <= 0 || !this.autoplay || this.timer) return;
      this.timer = setInterval(this.playSlides, this.interval);
    },

    setActiveItem(index) {
      let length = this.activeItems.length;
      let itemLength = this.items.length;
      if (index >= length && index < itemLength) {
        this.activeItems.push(...this.items.slice(length));
        this.$nextTick(() => {
          this.resetItemPosition(0);
          this.$nextTick(() => {
            this.setIndex(index);
          });
        });
      } else {
        this.setIndex(index);
      }
    },

    setIndex(index) {
      if (typeof index === "string") {
        const filteredItems = this.activeItems.filter(
          item => item.name === index
        );
        if (filteredItems.length > 0) {
          index = this.activeItems.indexOf(filteredItems[0]);
        }
      }
      index = Number(index);
      if (isNaN(index) || index !== Math.floor(index)) {
        return;
      }
      let length = this.activeItems.length;
      const oldIndex = this.activeIndex;
      if (index < 0) {
        this.activeIndex = this.loop ? length - 1 : 0;
      } else if (index >= length) {
        this.activeIndex = this.loop ? 0 : length - 1;
      } else {
        this.activeIndex = index;
      }
      if (oldIndex === this.activeIndex) {
        this.resetItemPosition(oldIndex);
      }
    },

    prev() {
      this.setActiveItem(this.activeIndex - 1);
    },

    next() {
      this.setActiveItem(this.activeIndex + 1);
    },

    handleIndicatorClick(index) {
      this.setActiveItem(index);
    },

    handleIndicatorHover(index) {
      if (this.trigger === "hover" && index !== this.activeIndex) {
        this.setActiveItem(index);
      }
    }
  }
};
</script>
<style scoped>
.el-carousel {
  overflow-x: hidden;
  position: relative;
}
.el-carousel__container {
  position: relative;
  height: 300px;
}
.el-carousel__arrow {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  height: 36px;
  width: 36px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 50%;
  background-color: rgba(31, 45, 61, 0.11);
  color: #fff;
  position: absolute;
  top: 50%;
  z-index: 10;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  font-size: 12px;
}
.el-carousel__arrow--left {
  left: 16px;
}
.el-carousel__arrow--right {
  right: 16px;
}
.el-carousel__arrow:hover {
  background-color: rgba(31, 45, 61, 0.23);
}
.el-carousel__arrow i {
  cursor: pointer;
}
.el-carousel__indicators {
  position: absolute;
  list-style: none;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin: 0;
  padding: 0;
  z-index: 2;
}
.el-carousel__indicators--outside {
  bottom: 26px;
  text-align: center;
  position: static;
  -webkit-transform: none;
  transform: none;
}
.el-carousel__indicators--outside .el-carousel__indicator:hover button {
  opacity: 0.64;
}
.el-carousel__indicators--outside button {
  background-color: #c0c4cc;
  opacity: 0.24;
}
.el-carousel__indicators--labels {
  left: 0;
  right: 0;
  -webkit-transform: none;
  transform: none;
  text-align: center;
}
.el-carousel__indicators--labels .el-carousel__button {
  height: auto;
  width: auto;
  padding: 2px 18px;
  font-size: 12px;
}
.el-carousel__indicators--labels .el-carousel__indicator {
  padding: 6px 4px;
}
.el-carousel__indicator {
  display: inline-block;
  background-color: transparent;
  padding: 12px 4px;
  cursor: pointer;
}
.el-carousel__indicator:hover button {
  opacity: 0.72;
}
.el-carousel__indicator.is-active button {
  opacity: 1;
}
.el-carousel__button {
  display: block;
  opacity: 0.48;
  width: 30px;
  height: 2px;
  background-color: #fff;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.carousel-arrow-left-enter,
.carousel-arrow-left-leave-active {
  -webkit-transform: translateY(-50%) translateX(-10px);
  transform: translateY(-50%) translateX(-10px);
  opacity: 0;
}

.carousel-arrow-right-enter,
.carousel-arrow-right-leave-active {
  -webkit-transform: translateY(-50%) translateX(10px);
  transform: translateY(-50%) translateX(10px);
  opacity: 0;
}
</style>
